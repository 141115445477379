@use '@angular/material' as mat;
@use 'sass:map';

@mixin x-order-incident-form-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);

  $gray: map-get(map-get($theme, colors), 'gray');

  $foreground: map-get($theme, foreground);
  $divider-color: mat.get-color-from-palette($foreground, divider);

  .incident-category-instructions {
    padding: 0 12px 12px;
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
  }

  .x-order-incident-item {
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    padding: 8px 16px;
    align-items: center;
    min-height: 68px;
    border-bottom: 1px solid mat.get-color-from-palette($primary, 50);
  }

  .x-order-incident-step {
    @extend .x-order-incident-item;

    &:first-of-type {
      border-top: 1px solid mat.get-color-from-palette($primary, 50);
    }

    &.active {
      background: mat.get-color-from-palette($accent, 400);
    }

    &.can-activate {
      cursor: pointer;
    }

    .step-text {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .step-title {
        font-size: 0.9rem;
      }

      .step-subtitle {
        font-size: 0.75rem;
      }
    }
  }

  .x-order-incident-category-selector {
    .category-selector {
      @extend .x-order-incident-item;

      justify-content: space-between;
      cursor: pointer;

      &.selected {
        background: mat.get-color-from-palette($accent, 400);
      }

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.6;
      }

      .category-text {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .category-name {
          font-size: 0.9rem;
        }

        .category-description {
          font-size: 0.75rem;
        }
      }
    }
  }

  .x-order-incident-order-item {
    @extend .x-order-incident-item;
    justify-content: space-between;

    .item-quantity {
      margin-left: auto;
      margin-right: 0;
    }

    .item-text {
      display: flex;
      flex-direction: column;
      gap: 1px;

      .item-subtitle {
        font-size: 0.75rem;
      }
    }
  }

  .x-order-incident-affected-items-table {
    table {
      width: 100%;
      min-width: 600px;
      td {
        vertical-align: middle;
        height: 60px;
        padding: 0 6px;
        @include mat.form-field-density(-4);
      }

      .product-name {
        line-height: 1;
      }

      .affected-item-action-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 6px;

        label {
          user-select: none;
          position: relative;
          margin-bottom: -8px;
          font-size: 0.9em;
        }
      }

      .nowrap {
        word-wrap: normal;
        white-space: nowrap;
      }
    }
  }

  .incident-summary {
    @extend .mat-typography;
    display: flex;
    flex-flow: row wrap;

    &-section {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      &.half {
        width: 50%;
      }
      &.third {
        width: calc(100% / 3);
      }
      &-title {
        @include mat.typography-level($typography, body-1);
        padding: 16px 12px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
      }
      &-content {
        flex-grow: 1;

        &:not(table) {
          padding: 0 15px 16px 15px;
          display: flex;
          flex-flow: column nowrap;
        }

        &:is(table) {
          th,
          td {
            line-height: 1.5;
            vertical-align: baseline;
          }
          th {
            padding: 6px 0;
            font-weight: normal;
          }
          td {
            padding: 6px 0 6px 6px;

            &:last-of-type {
              width: 100px;
            }
          }
          thead {
            th,
            td {
              border-bottom: mat.get-color-from-palette($gray, 200) 1px solid;
              color: mat.get-color-from-palette($gray, 600);
            }
          }
          tbody {
            tr {
              th,
              td {
                transition: background 100ms ease;
                width: 10vw;
                min-width: 80px;
                max-width: 150px;
              }
              th {
                width: auto;
              }
              &:hover {
                th,
                td {
                  background: mat.get-color-from-palette($accent, 100);
                }
              }
            }
          }
          tfoot {
            th,
            td {
              border-top: mat.get-color-from-palette($gray, 200) 1px solid;
              font-weight: bold;
            }
          }
        }
      }
      &:not(:last-of-type) {
        border-bottom: $divider-color 1px solid;
      }
    }
  }

  // .incident-summary-table {
  //   margin-top: 8px;
  //   thead {
  //     th,
  //     td {
  //       border-bottom: 1px solid $divider-color;
  //     }
  //   }

  //   th {
  //     text-align: left;
  //     font-weight: bold;
  //     padding: 8px 8px 8px 0;
  //   }

  //   td {
  //     text-align: left;
  //     padding: 8px 0 8px 4px;
  //   }
  // }
}
